<template>
  <znl-gridmodule
    gridtype="action"
    ref="flexGrid"
    layout-type="other"
    height="100%"
    :has-znl-btns="false"
    :columns="columns"
    :from-height="90"
    :is-show-frombtns="false"
    :is-init="true"
    :has-znl-actions="false"
    :isBtn="false"
    :item-source="itemSource"
    :hasDefaultBeforeNewRow="true"
    :is-fields-search="false"
    :is-multi-rows-check="false"
    :search-fields="searchModel"
    :show-right-menu="false"
    :role="role"
    :show-shortcut-column="false"
    :users="$store.state.users"
    :on-init="onInit"
  >
    <div slot="from-module" style="padding:0">
      <el-row>
        <el-col :span="5">
          <h3 style="font-size:16px;color:#4D4D4D;">合并后的库存信息:</h3>
        </el-col>
        <el-col :span="6">
          <znl-input
            title-width="80px"
            form-type="input"
            size="mini"
            layout="left"
            class="ipttop"
            placeholder
            :is-must-fill="false"
            :clearable="true"
            :border="true"
            title="销售指导价："
            width="100%"
            v-model="stock.followPrice"
            type="text"
          ></znl-input>
        </el-col>
        <el-col :span="6">
          <znl-input
            title-width="90px"
            form-type="input"
            class="ipttop"
            size="mini"
            layout="left"
            :clearable="true"
            :border="true"
            :is-must-fill="false"
            placeholder
            title="MarkingCode："
            width="100%"
            v-model="stock.markingCode"
            type="text"
          ></znl-input>
        </el-col>
        <el-col :span="6">
          <znl-input
            title-width="80px"
            form-type="input"
            size="mini"
            class="ipttop"
            layout="left"
            :clearable="true"
            :is-must-fill="false"
            :border="true"
            placeholder
            title="功能描述："
            width="100%"
            v-model="stock.explain"
            type="text"
          ></znl-input>
        </el-col>
      </el-row>
    </div>
  </znl-gridmodule>
</template>

<script>
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
export default {
  name: 'StkStockMerge',
  mixins: [getCommonDataMixin],
  components: {
  },
  data () {
    return {
      config: {
        saveMergeURL: 'StkStockMerge/Save'
      },
      stock: {// 库存对象
        followPrice: '',
        markingCode: '',
        explain: ''
      },
      columns: [],
      itemSource: [],
      columnColors: [],
      role: '1',
      btnState: true
    }
  },
  props: {
    height: String,
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    stockList: {
      type: Array,
      default: () => {
        return []
      }
    },
    markingCODE: String
  },
  watch: {
    stockList: {
      handler (value) {
        if (value !== undefined && value !== null) {
          this.onBindData()
        }
      },
      immediate: true
    }
  },
  methods: {
    onInit: async function () {
      let gtidConfig = [
        { CTitle: '产品类型',
          BindField: 'ProductCategory',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 100,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '型号',
          BindField: 'Model',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 150,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '品牌',
          BindField: 'Brand',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 2,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '封装',
          BindField: 'Packaging',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 3,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '批号',
          BindField: 'MakeYear',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 4,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '库存量',
          BindField: 'InvQty',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 5,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '包装',
          BindField: 'MPQ',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 6,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '位置',
          BindField: 'StorageName',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 100,
          DisplayIndex: 7,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '进价',
          BindField: 'BuyPrice',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 8,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '库存类型',
          BindField: 'InvType',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 60,
          DisplayIndex: 9,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '入库日期',
          BindField: 'StkInTime',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 72,
          DisplayIndex: 10,
          DataType: 4,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '采购员',
          BindField: 'BuyerName',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 48,
          DisplayIndex: 11,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '供应商',
          BindField: 'SupplierName',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 180,
          DisplayIndex: 12,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '备注',
          BindField: 'Remark',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 180,
          DisplayIndex: 13,
          DataType: 1,
          IsSystem: false,
          IsRequired: false }
      ]

      let columns = this.flexGridColumnsHandler(gtidConfig)
      // 下拉列表
      let stkTypeColumn = _.find(columns, column => column.name === 'InvType')
      console.log(await this.selectStorageType, 1234)
      stkTypeColumn && _.extend(stkTypeColumn, {
        dataMap: await this.selectStorageType,
        showDropDown: true
      })

      this.columns = columns
      this.onBindData()
    },
    onBindData () {
      if (this.stockList === null || this.stockList.length < 2) {
        return
      }
      let info = {
        ProductCategory: this.stockList[0].ProductCategory,
        Model: this.stockList[0].Model,
        Brand: this.stockList[0].Brand,
        Packaging: this.stockList[0].Packaging,
        MakeYear: this.stockList[0].MakeYear,
        MPQ: this.stockList[0].MPQ,
        StorageName: this.stockList[0].StorageName,
        InvPosition: this.stockList[0].InvPosition,
        BuyPrice: this.stockList[0].BuyPrice,
        FollowPrice: this.stockList[0].FollowPrice,
        MarkingCode: this.stockList[0].MarkingCode,
        Explain: this.stockList[0].Explain,
        Remark: this.stockList[0].Remark,
        InvType: this.stockList[0].InvType,
        BuyerName: this.stockList[0].BuyerName,
        SupplierName: this.stockList[0].SupplierName,
        Unit: this.stockList[0].Unit,
        SalesPrice: this.stockList[0].SalesPrice
      }
      let _totalQty = 0
      let _calTotalQty = 0//计算总数量（绝对值），用于计算进价
      let _calTotalAmount = 0//计算总金额（绝对值），用于计算进价
      _.each(this.stockList, item => {
        if (!this.hasValue(info.Partner)) {
          info.Partner = item.Partner
        }
        if (!this.hasValue(info.MarkingCode)) {
          info.MarkingCode = item.MarkingCode
        }
        if (!this.hasValue(info.PlatFormCodes)) {
          info.PlatFormCodes = item.PlatFormCodes
        }
        if (!this.hasValue(info.BuyerName)) {
          info.BuyerName = item.BuyerName
        }
        if (!this.hasValue(info.SupplierName)) {
          info.SupplierName = item.SupplierName
        }

        _totalQty += item.InvQty
        _calTotalQty += Math.abs(item.InvQty)

        let buyPrice = 0
        if (this.hasValue(item.BuyPrice)) {
          buyPrice = item.BuyPrice
        }
        _calTotalAmount += this.toFloat(Math.abs(buyPrice) * this.ConvertNumber(Math.abs(item.InvQty),6))

      })

      info.InvQty = _totalQty
      if (_calTotalQty > 0) {
        info.BuyPrice = this.toFloat(_calTotalAmount / this.ConvertNumber(_calTotalQty), 6)
      }
      info.StkInTime = new Date().toLocaleDateString()
      let item = []
      item.push(info)
      this.itemSource = item
    },
    ConvertNumber (num) {
      return (_.isNaN(num) || _.isUndefined(num)) ? null : this.toFloat(num, 6)
    },
    onReset () {
      this.stock.followPrice = ''
      this.stock.markingCode = ''
      this.stock.explain = ''
    },
    onSave () { // 确认合并
      let row = this.itemSource
      row[0].FollowPrice = this.stock.followPrice
      row[0].MarkingCode = this.stock.markingCode
      row[0].Explain = this.stock.explain
      // 库存量
      if (!_.isNumber(Number(row[0].InvQty))) {
        return this.$message({ message: '库存量格式不正确,必须为数值!', type: 'warning' })
      }
      if (!_.isNumber(Number(row[0].BuyPrice))) {
        return this.$message({ message: '进价格式不正确,必须为数值!', type: 'warning' })
      }
      if (_.isNaN(Number(row[0].FollowPrice))) {
        return this.$message({ message: '销售指导价格式不正确,必须为数值!', type: 'warning' })
      }

      if (!this.hasValue(row[0].InvType)) {
        this.$message({ message: '库存类型为必选项!', type: 'warning' })
        return
      }

      let models = _.uniq(_.map(this.stockList, item => item.Model))
      if (models.length >= 2) {
        return this.$confirm('有型号不同的库存，确认要合并吗?', '合并库存', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.saveData(row)
        })
      } else {
        this.saveData(row)
      }
    },

    saveData (val) {
      let row = []
      _.each(this.stockList, item => {
        row.push(item)
      })
      row.unshift(val[0])
      this.$emit('page-loading', true)
      return this.$post(this.config.saveMergeURL, row, (datas) => {
        if (datas > 0) {
          this.$message({ message: '库存合并成功', type: 'success' })
          this.$emit('save-success')
        }
      }).finally(() => {
        this.$emit('page-loading', false)
      })
    },
    onSaveSetting (columns, pageSize) {
      return this.$post(this.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.config.default))
    },
    onResetSetting () {
      return this.$post(this.config.resetConfigURL)
    }
  }
}
</script>

<style lang="scss">
.ipttop {
  padding-top: 18px;
}
</style>
