<template>
  <!-- 待合并库存列表 -->
  <znl-dialog
    :visible="visible"
    :title="headTitle"
    height="500px"
    width="800px"
    :append-to-body="true"
    v-loading="dialogLoading"
    :element-loading-text="LoadingText"
    @close="()=>{$emit('close')}"
  >
    <znl-layout-topbottom top-height="50%" slot="znl-dialog">
      <znl-gridmodule
        slot="znlTop"
        class="znlTop"
        ref="flexGrid"
        layout-type="other"
        :has-znl-btns="false"
        height="100%"
        :is-init="true"
        :columns="columns"
        :item-source="itemSource"
        :hasDefaultBeforeNewRow="true"
        :is-fields-search="false"
        :is-multi-rows-check="false"
        :search-fields="searchModel"
        :on-init="onInit"
      ></znl-gridmodule>
      <StkStockMergeAfter
        slot="znlBottom"
        class="znlBottom"
        ref="StkStockMergeAfter"
        :stockList="stockList"
        :stkGUIDS="stkGuids"
        :markingCODE="markingCode"
        :isRefresh="refreshMergelist"
        @page-loading="val=>{saveMergelist(val)}"
        @save-success="()=>{$emit('save-success')}"
      ></StkStockMergeAfter>
    </znl-layout-topbottom>
    <div slot="footer">
      <znl-button
        :height="33"
        :width="60"
        style-type="main"
        @click="()=>{$refs.StkStockMergeAfter.onSave()}"
      >确认合并</znl-button>
    </div>
  </znl-dialog>
</template>

<script>

import StkStockMergeAfter from '@c_modules/Stk/StkStockMergeAfter'

export default {
  name: 'StkStockMerge',
  components: {
    StkStockMergeAfter
  },
  data () {
    return {
      config: {
        configURL: 'StkStockMerge/GetConfig',
        saveConfigURL: 'StkStockMerge/SaveConfig',
        resetConfigURL: 'StkStockMerge/ResetConfig',
        searchURL: 'StkStock/GetStockListByGuids'
      },
      headTitle: '', // 标题
      stkGuids: '',
      refreshMergelist: false, // 是否刷新选择列表
      columns: [],
      itemSource: [],
      fieldsFilter: {},
      avgBuyPrice: 0,
      dialogLoading: true,
      LoadingText: '数据加载中,请稍后...',
      stockList: []
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    visible: Boolean,
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String,
    stkGUIDS: Array, // 库存对象
    markingCode: String,
    isRefresh: Boolean// 是否需要刷新页面
  },

  methods: {
    onInit: async function () {
      let gtidConfig = this.InitCol()
      let columns = this.flexGridColumnsHandler(gtidConfig)
      this.columns = columns
      this.headTitle = '待合并库存列表：(共选择了' + this.stkGUIDS.length + '条库存)'
      this.onBindData()
    },
    onBindData () {
      this.dialogLoading = true
      return this.$post(this.config.searchURL, { StkGUIDS: this.stkGUIDS }, (datas) => {
        this.itemSource = datas
        this.stockList = datas
        this.refreshMergelist = !this.refreshMergelist
      }).finally(() => {
        this.dialogLoading = false
      })
    },
    onSaveSetting (columns, pageSize) {
      return this.$post(this.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.config.default))
    },
    onResetSetting () {
      return this.$post(this.config.resetConfigURL)
    },
    saveMergelist (state) {
      this.LoadingText = '正在合并数据,请稍后...'
      this.dialogLoading = state
    },
    InitCol () {
      let gtidConfig = [
        { CTitle: 'SID',
          BindField: 'SID',
          IsShow: false,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 201,
          DataType: 1,
          IsSystem: true,
          IsRequired: false },
        { CTitle: 'StkGUID',
          BindField: 'StkGUID',
          IsShow: false,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 100,
          DataType: 1,
          IsSystem: true,
          IsRequired: false },
        { CTitle: '产品类型',
          BindField: 'ProductCategory',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 100,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '型号',
          BindField: 'Model',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 120,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '品牌',
          BindField: 'Brand',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 2,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '封装',
          BindField: 'Packaging',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 3,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '批号',
          BindField: 'MakeYear',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 4,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '库存量',
          BindField: 'InvQty',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 5,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '包装',
          BindField: 'MPQ',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 6,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '位置',
          BindField: 'StorageName',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 100,
          DisplayIndex: 7,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '进价',
          BindField: 'BuyPrice',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 8,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '库存类型',
          BindField: 'InvType',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 60,
          DisplayIndex: 9,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '入库日期',
          BindField: 'StkInTime',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 72,
          DisplayIndex: 10,
          DataType: 4,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '采购员',
          BindField: 'BuyerName',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 48,
          DisplayIndex: 11,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '供应商',
          BindField: 'SupplierName',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 180,
          DisplayIndex: 12,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '备注',
          BindField: 'Remark',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 180,
          DisplayIndex: 13,
          DataType: 1,
          IsSystem: false,
          IsRequired: false }
      ]
      return gtidConfig
    }

  }
}
</script>

<style lang="scss">
</style>
